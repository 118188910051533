<template>
  <div class="myticket" v-if="loadComponent">
    <v-container>
      <notification-popup 
        :dialog="notifFailed"
        :contentMsg="contentMsg"
        :headerMsg="$t('Wrong')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelOk')"
        colorIcon = "danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        imgSource="notification/failed.svg"
        disableBtnNo="true"
        :onHandlerYes="buttonOkeFailed"/>

      <v-row
        v-if="isActiveAccount == false"
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('../../assets/bus-homepage.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0 mt-4" cols="12">
          <h2 class="notification">{{ $t("SuspendWarning") }}</h2>
        </v-col>
      
      </v-row>
      <v-row
        v-else-if="itemFF.length === 0 && itemMD.length === 0 && itemSD.length === 0"
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('@/assets/icon/no-ticket.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0" cols="12">
          <h2 class="notfication">{{ $t("NoTicket") }}</h2>
        </v-col>
        <v-col class="d-flex justify-center mt-3">
          <v-btn
            v-if="$partnerACL.isShowButton('purchase')"
            :color="$partnerACL.getButtonColor()"
            rounded
            width="80%"
            class="capitalize body-1"
            @click="clickPurchase()"
          >
            {{ $t("PurchaseTicket") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        v-for="item in itemFF"
        :key="item.ticket_token"
      >
        <ticketffcard
          :itemFF="item"
          :busQr="busqr"
        ></ticketffcard>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import ticketffcard from "@/components/CardTicketFlateGobus.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";

// import ticketmdcard from "@/components/CardTicketMultiDaysGobus.vue";
export default {
  name: "MyTicket",
  components: { ticketffcard, NotificationPopup },
  data() {
    return {
      contentMsg:"",
      notifFailed: false,
      busqr: "",
      refreshToken: "",
      itemFF: [],
      itemMD: [],
      itemSD: [],
      psg_id: store.state.auth.userData.psg_id,
      isActiveAccount: true,
      loadComponent:false
    };
  },
  methods: {
    clickPurchase() {
      const purchaseButton = this.$partnerACL.getMetaButton('purchase');
      if(purchaseButton){
        if(purchaseButton.menu_type == "wsc_menu"){
            this.$router.push({
              name: purchaseButton.redirect_name,
            });
        }else {
          window.location = purchaseButton.redirect_path;
        }
      }else {
        this.$router.push({
          name: "Ticket",
        });
      }
    },
    buttonOkeFailed(){
      this.$router.push({
        name: "Home",
      });
    }
  },
  mounted() {
    if (this.$route.params.refreshtoken && this.$route.params.busqr) {
      this.refreshToken = this.$route.params.refreshtoken;
      this.busqr = this.$route.params.busqr;
      
      this.$store
        .dispatch("auth/refreshTokenBP", { rToken: this.refreshToken })
        .then(async (status) => {
          if (status) {
            let shouldUpdate = false;
            await this.$store
              .dispatch("passenger/GetDataById")
              .then((response) => {
                console.log("this setAuth",this.$store.commit("auth/SET_IS_AUTHENTICATED", 1));
                shouldUpdate= response.data.psg_first_name =="n.a" || response.data.psg_last_name =="n.a" || 
                response.data.psg_phone_number =="n.a" || response.data.psg_birthday =="0001-01-01T00:00:00Z" 
                // redirect to register update if the user is new and user data not complete yet after user scan qr code from gobus
                if(shouldUpdate) this.$router.push({ name: "RegisterUpdate" });

                this.$store.commit("auth/SET_IS_AUTHENTICATED", true);
                console.log("this auth bawah", this.$store.commit("auth/SET_IS_AUTHENTICATED", 1));
              })  
              .catch((err) => {
                console.log(err);
              });
            
            if(!shouldUpdate){
              const payload = {
                bus_qr:this.busqr
              };
              this.$store
                .dispatch("myticket/gobusticket", payload)
                .then((response) => {
                  this.isActiveAccount = true
                  if(response.message_code == 14){
                    this.notifFailed=true
                    this.contentMsg=this.$t("YourQrCodeWrong")
                  } else if(response.message_code == 15) {
                    this.isActiveAccount = false;
                  }
                  this.itemFF = [];
                  for (let data of response.data) {
                    this.itemFF.push(data);
                  }
                  const payloadd = {
                    cart_psg_id: this.psg_id,
                    bus_qr : this.busqr
                  };
                  this.$store.dispatch("purchaseticket/getQtyTicket", payloadd)
                  this.$store.commit("auth/SET_IS_AUTHENTICATED", true);
                  this.loadComponent=true
                })
                .catch((err) => {
                  this.$store.commit("purchaseticket/GETQTYTICKETBUS_SUCCESS", 0);
                  console.log(err);
                  this.loadComponent=true
                });
            }
          } else {
            console.log("Auto Login Failed");
            this.loadComponent=true
          }
        })
        .catch(() => {
          console.log("Auto Login Failed");
          this.loadComponent=true
        });
    } 
    


  },
};
</script>
<style lang="scss" scoped>
.myticket {
  background-color: rgba(245, 255, 247, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.flex-wrapper-one {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 20px;
}
.back-img {
  margin-right: 12px;
}
.my-ticket {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: black;
  margin: 0;
}
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #ffffff 0%, #ffe9fc 100%);
}
.null-item {
  position: absolute;
  top: 46%;
  right: 50%;
  transform: translate(46%, -50%);
}
.capitalize {
  text-transform: capitalize;
}

.notification {
  text-align: center;
}
</style>