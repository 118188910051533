<template>
  <v-col cols="12" class="content pa-0 mx-4 my-2">
      <notification-popup  
        :dialog="notifSuccess"
        :contentMsg="contentMsg"
        :headerMsg="$t('SuccessScanQR')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('BacktoRecentTrip')"
        disableBtnNo="true"
        colorIcon = "success"
        typeModal="transaction"
        Icon="mdi-check-circle"
        imgSource="notification/success2.svg"
        :onHandlerNo="cancelSuccess"
        :onHandlerYes="buttonOke"/>

      <notification-popup 
        :dialog="notifFailed"
        :contentMsg="contentMsg"
        :headerMsg="$t('Failed')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('Cancel')"
        colorIcon = "danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        imgSource="notification/failed.svg"
        disableBtnNo="true"
        :onHandlerYes="buttonOkeFailed"/>
    <v-card elevation="1" class="card-content py-2" :class="
        itemFF.fare_type == '01' ? 'card-content' : 'card-content-multi'
      ">
      <v-row align="center" class="ma-0 pa-0" v-if="itemFF.fare_type == '01'">
        <v-col class="ma-0 pa-0" cols="8">
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-1">
            <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
              <div class="route-1">{{ itemFF.fare_name }}</div>
              <v-icon v-on:click="clickRouteInformation()" class="pl-1" x-small
                >mdi-information-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row align="center" class="ma-0 pa-4 pb-0 pt-0">
            <v-col class="ma-0 pa-0" cols="5">
              <p class="bth d-flex justify-center">{{ itemFF.orig_loc }}</p>
              <p class="place-desc d-flex justify-center">{{ itemFF.original_loc }}</p>
            </v-col>
            <v-col class="mb-4 pa-0 d-flex justify-center" cols="2">
              <img
                alt=""
                class="vector"
                :src="require('@/assets/buy-ticket/bus-vector.svg')"
              />
            </v-col>
            <v-col class="ma-0 pa-0" cols="5">
              <p class="bth d-flex justify-center">{{ itemFF.dest_loc }}</p>
              <p class="place-desc d-flex justify-center">
                {{ itemFF.destination_loc }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <img class="divider" :src="require('@/assets/divider-ticket.svg')" />
        <v-col class="d-flex justify-center col-kanan" cols="4">
          <v-row>
            <v-col class="text-center px-0 py-0 mt-n2">
              <span style="font-size: 0.60rem">{{itemFF.ticket_number}}</span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="py-0">
              <v-btn
                class="text-capitalize btn-buy-hijau"
                v-on:click="useTicket(itemFF.ticket_token)"
                rounded
                outlined
                min-width="70px"
                color="primary"
                small
              >
                {{ $t("Use") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" class="ma-0 pa-0" v-if="itemFF.fare_type == '03'">
        <v-col class="ma-0 pa-0" cols="8">
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-1">
            <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
              <h3 class="black--text font-weight-bold">
                {{$t("BusTicket")}}
                <!-- {{ itemFF.valid_days }} <span style="text-transform: lowercase;">{{$t("Days")}} , {{ itemFF.max_trip }} {{$t("Trips")}} </span> -->
              </h3>
            </v-col>
          </v-row>
          <v-row align="center" class="ma-0 pa-4 pb-0 pt-0">
            <v-col class="ma-0 pa-0 text-center" cols="12">
              <p class="bth d-flex justify-center">{{ itemFF.fare_name }}</p>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-0">
            <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
              <div class="rule-2">
                &#9679; 
                  <span v-if="$i18n.locale == 'vi'"> Tập {{ itemFF.max_trip }} vé</span> 
                  <span v-else>{{ itemFF.max_trip }} trips</span> 
                &#9679;
              </div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-0 pa-0 pt-0">
            <v-col class="subtitle-2 d-flex justify-center pa-0 ma-0 text-center" cols="12">
              {{$t("PurchaseDate")}} : {{itemFF.created_at}}
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-0 pa-0">
            <v-col class="subtitle-2 d-flex justify-center pa-0 ma-0 text-center" cols="12">
              {{ $t("TicketsRemaining") }} {{itemFF.max_trip - itemFF.used_ticket }} {{ $t("TicketsRemainingDetail")}}
            </v-col>
          </v-row>
        </v-col>
        <img class="divider" :src="require('@/assets/divider-ticket.svg')" />
        <v-col class="justify-center col-kanan" cols="4">
          <v-row>
            <v-col class="text-center px-0 py-0 mt-n2">
              <span style="font-size: 0.60rem">{{itemFF.ticket_number}}</span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="py-0">
              <v-btn
                class="text-capitalize btn-buy-hijau"
                v-on:click="useTicket(itemFF.ticket_token)"
                rounded
                outlined
                min-width="70px"
                color="primary"
                small
              > {{ $t("Use") }} </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
import moment from "moment"
export default {
  name: "FlateTicket",
  components :{NotificationPopup},
  props: {
    itemFF: {
      type: Object
    },
    busQr : {
      type: String
    }
  },
  data(){
    return{
      notifSuccess:false,
      notifFailed:false,
      contentMsg:""
    }
  },
  methods: {
    useTicket(ticket_token) {
      // this.$emit("onClickView", this.params.value)
      // this.$router.push({
      //   name: "ViewTicket",
      //   params: {
      //     data: this.itemFF,
      //   },
      // });
      const payload = {
      bus_qr:this.busQr,
      ticket_token:ticket_token
    };
      this.$store
        .dispatch("mpm/validate", payload)
        .then((response) => {
          // console.log("respone from vuex :",response)
          if (response.status) {
            this.notifSuccess = true
            this.notifFailed = false
            this.contentMsg = this.$t('Enjoyyourtrip')
          }else{
            this.notifFailed = true
            this.notifSuccess = false
            this.contentMsg = this.$t(response.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buttonOke(){
      this.notifSuccess = false
      if (this.notifSuccess == false) this.$router.push({ name: "MyTrip" });
    },
    buttonOkeFailed(){
      this.notifFailed = false
    },
    cancelFailed(){
      this.notifFailed=false;
    },
    cancelSuccess(){
      this.notifSuccess=false;
    },
  },
  mounted(){
    this.itemFF.created_at = moment.utc(this.itemFF.created_at).format('DD/MM/YYYY HH:mm')
  }
};
</script>

<style lang="scss" scoped>
.divider {
  border: none;
  width: 2px;
  height: 110px;
}
.v-card.theme--light.v-sheet {
  // position: absolute;
  // border: 0.5px solid #c5c5c5;
  overflow: hidden;
}
.v-application .elevation-1 {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.col-kanan {
  height: 100%;
}

.halfcircle {
  background: #ffffff;
  width: 12.1px;
  height: 22.1px;
  position: absolute;
  top: 45%;
  border: 1px solid black;
  overflow: hidden;
  z-index: 5;
}

.cut-out {
  border-radius: 50px;
  width: 100%;
  height: 20%;
  display: block;
  position: absolute;
  top: 40%;
  background: #fdfdfd;
}

.halfcircle-1 {
  left: -97%;
  box-shadow: inset -1px 2px 2px -1px #888;
}
.halfcircle-2 {
  right: -97%;
  box-shadow: inset 1px 1px 1px 1px #888;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}
.crop-kiri {
  margin-right: 0%;
  height: 200px;
}
.crop-kanan {
  margin-left: -10%;
  height: 200px;
}
.card-content {
  // width: 90%;
  border: 2px solid #732269;
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.card-content-multi {
  // width: 90%;
  border: 2px solid #f29900;
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.content {
  margin-bottom: -12px;
}
.component-1 {
  float: left;
  width: 100%;
  position: relative;
}
.btn-buy-hijau {
  width: 100%;
}
.content-ticket {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
  padding: 1em;
}
.background-ticket {
  display: block;
  width: 100%;
}
.route-1 {
  color: rgba(134, 134, 134, 1);
  font-size: 10px;
}
.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 0px;
  display: flex;
  align-items: center;
}
.bth {
  font-size: 15px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: rgba(72, 158, 75, 1);
}
.relative-wrapper-one {
  margin-right: 15px;
  position: relative;
}
.vector {
  margin-top: 10px;
  width: 80%;
  height: auto;
}
.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}
.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
  // padding: 0 0 0 21px;
  // display: flex;
  // align-items: flex-start;
}
.bn-thanh {
  // @include roboto-10-light;
  margin-top: -15px;
  font-size: 9px;
  color: rgba(75, 177, 78, 1);
  right: 122px;
}
.place-desc {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  white-space: nowrap;

  margin-top: -3px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  padding-bottom: 0;
}
.vector-7 {
  width: 100%;
  margin-bottom: 9px;
}
.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}
.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.route-information {
  // @include roboto-12-medium;
  font-size: 8px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: 0px;
  margin-left: 25%;
  margin-bottom: -2px;
}
.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  margin-left: 25%;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}
.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}
.group-18785 {
  border-radius: 23px;
  height: 31px;
  width: 80px;
  padding: 5px 6px 2px 4px;
  position: relative;
  border: 1px solid rgba(75, 177, 78, 0.35);
}

.class-btn-buy {
  height: 20px;
  // border-radius: 23px;
  // height: 29px;
  // padding: 2px 6px 2px 4px;
  // border: 1px solid rgba(75, 177, 78, 0.35);
  // position: absolute;
  // width: 76px;
  // height: 29px;
  // left: 300px;
  // top: 535px;

  // background: #4BB14E;
  /* Button */

  // box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.1);
}
.flex-wrapper-one {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 41px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.flex-wrapper-two {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  position: absolute;
  left: -10px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}
.hyphen {
  width: 5px;
  height: 2px;
  background-color: rgba(75, 177, 78, 1);
}
.label {
  width: 64px;
  // @include roboto-10-bold;
  color: rgba(75, 177, 78, 1);
  text-align: center;
  position: relative;
}
.btn-plus {
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  font-size: 20px;
  border: 1px solid rgba(75, 177, 78, 1);
  z-index: 2;
  background-color: #ffffff !important;
  color: rgba(75, 177, 78, 1);
  left: 45px;
}
.btn-minus {
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  background-color: #ffffff !important;
  color: rgba(75, 177, 78, 1);
  border: 1px solid rgba(75, 177, 78, 1);
  z-index: 2;
  font-size: 20px;
  left: -40px;
}
.icon-plus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.icon-minus {
  height: 6px;
  width: 1px;
  left: -1px;
}
.input-label {
  // left: 4px;
  top: -30px;
}
.onHover {
  cursor: pointer;
}
</style>